<template>
  <div>
    <div class="card">
      <!--begin::Body-->
      <div class="card-body p-lg-17">
        <!--begin::Row-->
        <div class="row mb-3">
          <!--begin::Col-->
          <div class="col-md-6 pe-lg-10">
            <!--begin::Form-->
            <Form
              class="form mb-15 fv-plugins-bootstrap5 fv-plugins-framework"
              method="post"
              @submit="onSubmitEmail"
              id="contact-support-form"
              :validation-schema="formValidation"
            >
              <h2 class="fw-bolder text-dark mb-1">Create a Support Ticket</h2>
              <!--begin::Input group-->
              <div class="row mb-5">
                <!--begin::Col-->
                <div class="col-md-6 fv-row fv-plugins-icon-container">
                  <!--begin::Label-->
                  <label class="fs-5 fw-bold mb-2">First Name</label>
                  <!--end::Label-->
                  <!--begin::Input-->
                  <Field
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    placeholder=""
                    name="fromFirstName"
                    autocomplete="off"
                    v-model="emailRequest.fromFirstName"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="fromFirstName" />
                    </div>
                  </div>
                  <!--end::Input-->
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-md-6 fv-row fv-plugins-icon-container">
                  <!--end::Label-->
                  <label class="fs-5 fw-bold mb-2">Last Name</label>
                  <!--end::Label-->
                  <!--begin::Input-->
                  <Field
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    placeholder=""
                    name="fromLastName"
                    autocomplete="off"
                    v-model="emailRequest.fromLastName"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="fromLastName" />
                    </div>
                  </div>
                  <!--end::Input-->
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="d-flex flex-column mb-5 fv-row">
                <!--begin::Label-->
                <label class="fs-5 fw-bold mb-2">Email</label>
                <!--end::Label-->
                <!--begin::Input-->
                <Field
                  class="form-control form-control-lg form-control-solid"
                  type="email"
                  placeholder=""
                  name="fromEmail"
                  autocomplete="off"
                  v-model="emailRequest.fromEmail"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="fromEmail" />
                  </div>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="d-flex flex-column mb-5 fv-row">
                <!--begin::Label-->
                <label class="fs-5 fw-bold mb-2">Subject</label>
                <!--end::Label-->
                <!--begin::Input-->
                <Field
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  placeholder=""
                  name="subject"
                  autocomplete="off"
                  v-model="emailRequest.subject"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="subject" />
                  </div>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div
                class="
                  d-flex
                  flex-column
                  mb-10
                  fv-row fv-plugins-icon-container
                "
              >
                <label class="fs-6 fw-bold mb-2">Message</label>
                <textarea
                  class="form-control form-control-solid rounded-3"
                  rows="6"
                  v-model="emailRequest.message"
                ></textarea>
              </div>
              <!--end::Input group-->
              <!--begin::Submit-->
              <button
                id="contact-expert-submit"
                ref="submitButton"
                type="submit"
                class="btn btn-lg btn-primary"
              >
                <span class="indicator-label"> Submit </span>
                <span class="indicator-progress">
                  Please wait...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
              <!--end::Submit-->
              <div></div>
            </Form>
            <!--end::Form-->
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { SupportEmailRequestDto } from "../core/types/EmailTypes";

export default defineComponent({
  name: "contact-support",
  components: { Field, Form, ErrorMessage },
  setup() {
    const store = useStore();
    const currentUser = store.getters.currentUser;
    const submitButton = ref<HTMLElement | null>(null);
    const emailRequest = ref<SupportEmailRequestDto>({
      toName: "Brad Dassow",
      toEmail: "brad@launchcodedevelopment.com",
      ccEmail: "",
      clientId: 0,
      fromFirstName: currentUser.firstName,
      fromLastName: currentUser.lastName,
      fromEmail: currentUser.email,
      subject: "",
      message: "",
    });

    const formValidation = Yup.object().shape({
      fromFirstName: Yup.string().required().label("First Name"),
      fromLastName: Yup.string().required().label("Last Name"),
      fromEmail: Yup.string().min(4).required().email().label("Email"),
      subject: Yup.string().required().label("Subject"),
    });

    onMounted(() => {
      setCurrentPageTitle("Contact Support");
      setCurrentPageBreadcrumbs("Create a Support Ticket", ["Support"]);
    });

    const onSubmitEmail = () => {
      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // Send login request
      store
        .dispatch(Actions.EMAIL_SUPPORT, emailRequest.value)
        .then(() => {
          Swal.fire({
            text: "Success. The email has been sent.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Continue",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            resetForm();
          });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    function resetForm() {
      emailRequest.value = {
        toName: "",
        toEmail: "",
        ccEmail: "",
        clientId: 0,
        fromFirstName: currentUser.firstName,
        fromLastName: currentUser.lastName,
        fromEmail: currentUser.email,
        subject: "",
        message: "",
      };
    }

    return {
      currentUser,
      submitButton,
      emailRequest,
      formValidation,
      onSubmitEmail,
      resetForm,
    };
  },
});
</script>
